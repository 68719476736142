<script setup>
import DialogCommon from "@/components/Common/DialogCommon.vue"
import { defineAsyncComponent, onMounted, ref } from "vue"
import { useGlobalStore } from "@/stores/global"
import { useUpdateBackupStore } from "@/stores/updateBackup"

const props = defineProps({
  applyAllUpdates: {
    type: Boolean,
    default: false,
  },
  applyLatestUpdate: {
    type: Boolean,
    default: false,
  },
})

const UpdateError = defineAsyncComponent(
  () => import("@/components/Init/UpdateError.vue")
)

const storeGlobal = useGlobalStore()
const storeUpdateBackup = useUpdateBackupStore()
const { $toast } = useNuxtApp()

const error = ref(null)
const showDialog = ref(true)

function runUpdates() {
  storeUpdateBackup.backupData()

  const versionToUpdateFrom =
    storeGlobal.versionToUpdateFrom || storeGlobal.appVersionLastSeen
  console.log("Version to update from", versionToUpdateFrom)

  try {
    const updateOptions = {
      applyAllUpdates: props.applyAllUpdates,
      applyLatestUpdate: props.applyLatestUpdate,
      versionToUpdateFrom,
    }
    storeGlobal.updateModifiersGlobal(updateOptions)
    storeGlobal.updateProfiles(updateOptions)
    storeGlobal.versionToUpdateFrom = null
    $toast.success("Update successful.")
  } catch (error) {
    // TODO: This doesn't seem to get triggered on a failed update any more.
    console.error(error)
    error.value = error
    // Restore backup data if there was an error,
    // otherwise the error could be different every time.
    storeUpdateBackup.restoreBackupData()
  }

  if (!error.value) {
    // Only clear backup data once we've applied all updates without error.
    storeUpdateBackup.clearBackupData()
    storeGlobal.updateCurrentProfiles()
    storeGlobal.syncAppVersions()
    storeGlobal.updateLastSeen()
    storeGlobal.updateInProgress = false
  }
}

onMounted(() => {
  // if (import.meta.env.VITE_APP_ENVIRONMENT === "ci") {
  runUpdates()
  // } else {
  //   // This is here for UX purposes.
  //   // If we don't delay the updates slightly, the user never sees the progress bar
  //   // and everything looks way more janky.
  //   // TODO: Can remove this when I've moved the update process to a separate thread.
  //   setTimeout(() => {
  //     runUpdates()
  //   }, 1500)
  // }
})
</script>

<template>
  <DialogCommon v-model="showDialog" max-width="700px" :persistent="true">
    <UpdateError v-if="error" :error="error" class="wrapper" />
    <div v-else class="wrapper no-update-error">
      <h2 class="mb-2">Updating local data</h2>
      <div class="mb-4"><small>PLEASE HOLD</small></div>
      <v-progress-linear :indeterminate="true" color="primary" />
    </div>
  </DialogCommon>
</template>

<style lang="scss" scoped>
.wrapper {
  background-color: #1e1e1e;
  padding: 2rem;
  & > *:last-child {
    margin-bottom: 0;
  }
}
.wrapper.no-update-error {
  text-align: center;
}
</style>
